import React from 'react';
import './HealthTips.css';

function HealthTips() {
    return (
        <div className="health-tips-container">
            <div className="main-content">
                <h2>Sağlık Tavsiyeleri ve Motivasyonel Sözler</h2>
                <div className="main-video-container">
                    <iframe
                        width="100%"
                        height="315"
                        src="https://www.youtube.com/embed/dGPQhqje9OE?autoplay=1"
                        title="YouTube video player"
                        frameBorder="0"
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                        allowFullScreen
                    ></iframe>
                </div>
                <ul>
                    <li>
                        <strong>"Sigarayı bırakmak, kendinize yapabileceğiniz en büyük iyiliklerden biridir."</strong>
                        Kendinize ve sevdiklerinize daha sağlıklı bir yaşam sunmak için bu kararı aldınız ve bu çok
                        büyük bir adım. Unutmayın, sağlığınızı geri kazanmak her geçen gün mümkün.
                    </li>
                    <li>
                        <strong>"24 saat sonra kalp krizi riski azalmaya başlar."</strong>
                        Sigara içmediğiniz ilk günün sonunda, kalp sağlığınızda iyileşmeler başlayacak. Her nefes sizi
                        daha güçlü ve sağlıklı biri yapıyor.
                    </li>
                    <li>
                        <strong>"2 hafta ila 3 ay içinde kan dolaşımınız düzelir, akciğer fonksiyonları
                            iyileşir."</strong>
                        Artık merdiven çıkarken daha az nefessiz kaldığınızı fark edeceksiniz. Daha aktif ve hareketli
                        olmanın tadını çıkarın.
                    </li>
                    <li>
                        <strong>"1 yıl sonra koroner kalp hastalığı riski yarı yarıya azalır."</strong>
                        Bırakmanın ardından 1 yıl geçtikten sonra kalbiniz daha güçlü olacak ve uzun yıllar sağlıklı bir
                        yaşam sürme ihtimaliniz artacak.
                    </li>
                    <li>
                        <strong>"5 yıl sonra felç riski sigara içmeyenlerle aynı seviyeye iner."</strong>
                        Sigarayı bırakarak, beyninizdeki damarların sağlığını korumuş oluyorsunuz. Bu da sizin için daha
                        keskin bir zihin ve daha az risk demek.
                    </li>
                    <li>
                        <strong>"10 yıl sonra akciğer kanseri riski yarı yarıya azalır."</strong>
                        Sigara bırakma kararınız, kanser riskinizi azaltmanın ve akciğerlerinizi korumanın en etkili
                        yoludur. Daha uzun ve sağlıklı bir hayat sizin elinizde.
                    </li>
                    <li>
                        <strong>"Sigarasız geçen her gün, sağlığınıza yatırım yapmaktır."</strong>
                        Bedeninizin her gün daha temiz ve daha güçlü bir hale geldiğini unutmayın. Sigara içmediğiniz
                        her gün, bedeninizin kendini iyileştirmesine ve yenilemesine fırsat veriyorsunuz.
                    </li>
                    <li>
                        <strong>"Daha temiz bir nefes, daha keskin bir koku ve tat alma duyusu."</strong>
                        Sigarayı bıraktıktan sonra, yemeklerin ve çevrenizdeki kokuların ne kadar güzel olduğunu yeniden
                        keşfetmeye hazır olun.
                    </li>
                    <li>
                        <strong>"Daha fazla enerji, daha mutlu bir yaşam."</strong>
                        Sigara içmediğinizde, enerjinizin nasıl arttığını ve günlük aktivitelerde daha fazla keyif
                        aldığınızı fark edeceksiniz. Daha sağlıklı ve mutlu bir yaşamın kapılarını araladınız.
                    </li>
                    <li>
                        <strong>"Sevdiklerinizle daha fazla zaman geçirme fırsatı."</strong>
                        Sigarayı bırakarak, sevdiklerinizle geçireceğiniz zamanı artırdınız. Hem kendiniz hem de aileniz
                        için en değerli olan şeyi, zamanı, kazanıyorsunuz.
                    </li>
                    <li>
                        <strong>"Küçük adımlar büyük fark yaratır."</strong>
                        Sigara içme isteği geldiğinde derin bir nefes alın ve bu isteğin geçici olduğunu kendinize
                        hatırlatın. Her küçük adım sizi daha büyük bir başarıya götürür.
                    </li>
                    <li>
                        <strong>"Daha sağlıklı bir cilt, daha parlak bir görünüm."</strong>
                        Sigarayı bırakmanızla birlikte cildinizdeki değişimi fark edeceksiniz. Daha parlak ve sağlıklı
                        bir ciltle kendinizi daha iyi hissedeceksiniz.
                    </li>
                </ul>
            </div>
            <div className="sidebar">
                <h3>Ekstra Motivasyon Videosu</h3>
                <div className="sidebar-video-container">
                    <iframe
                        width="560"
                        height="315"
                        src="https://www.youtube.com/embed/k7-wlBf5rGM"
                        title="Motivasyon Videosu"
                        frameBorder="0"
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                        allowFullScreen
                    ></iframe>
                    <iframe width="560" height="315" src="https://www.youtube.com/embed/PbpgvaB9tdk?si=ewnLjOFmu78gvbfS"
                            title="YouTube video player" frameBorder="0"
                            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                            referrerPolicy="strict-origin-when-cross-origin" allowFullScreen></iframe>
                    <iframe width="560" height="315" src="https://www.youtube.com/embed/URFbCjhdbas?si=ljo0FfI3jW8Aduds"
                            title="YouTube video player" frameBorder="0"
                            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                            referrerPolicy="strict-origin-when-cross-origin" allowFullScreen></iframe>
                </div>
            </div>
        </div>
    );
}

export default HealthTips;
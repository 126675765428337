import React, { useState } from 'react';
import Form from './components/Form';
import Results from './components/Results';
import HealthTips from './components/HealthTips';
import './App.css';
import {Toaster} from "react-hot-toast";

function App() {
  const [formData, setFormData] = useState(null);

  return (
      <div className="App">
        <h1>Sigara Bırakma Takip Uygulaması</h1>
        <Form setFormData={setFormData} />
        {formData && <Results formData={formData} />}
        <HealthTips />
      </div>
  );
}

export default App;
import React from 'react';

function Results({ formData }) {
    // Hesaplamalar
    const baslamaTarihi = new Date(formData.sigaraBaslama);
    const birakmaTarihi = new Date(formData.sigaraBirakma);
    const gunlukSigara = parseInt(formData.gunlukSigara);
    const paketFiyati = parseFloat(formData.paketFiyati);

    const farkZaman = Math.abs(birakmaTarihi - baslamaTarihi);
    const gunSayisi = Math.ceil(farkZaman / (1000 * 60 * 60 * 24));

    const toplamIcilenSigara = gunlukSigara * gunSayisi;
    const toplamHarcama = ((toplamIcilenSigara / 20) * paketFiyati).toFixed(2); // 1 paket = 20 sigara

    return (
        <div className="results">
            <h2>Sonuçlar</h2>
            <p>Sigara içilen toplam gün: {gunSayisi} gün</p>
            <p>Toplam içilen sigara sayısı: {toplamIcilenSigara} adet</p>
            <p>Toplam harcanan para: {toplamHarcama} TL</p>
            <p>Sigara içmeyerek sağlığınıza yaptığınız katkıyı unutmayın!</p>
        </div>
    );
}

export default Results;
import React, { useState } from 'react';
import toast from "react-hot-toast";

function Form({ setFormData }) {
    const [data, setData] = useState({
        boy: '',
        kilo: '',
        yas: '',
        sigaraBaslama: '',
        sigaraBirakma: '',
        gunlukSigara: '',
        paketFiyati: '',
        marka: '',
        birakmaNedeni: ''
    });

    const handleChange = (e) => {
        setData({
            ...data,
            [e.target.name]: e.target.value,
        });
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        if(data.boy !== ''&&data.boy !== ''&&data.kilo !== ''&&data.yas !== ''&&data.paketFiyati!==''&&data.birakmaNedeni!== ''&&data.sigaraBaslama!==''&&data.sigaraBirakma!==''&&data.gunlukSigara!=='') {
            setFormData(data);
        }else {
            alert("Boş alan bırakmayınız!")
        }

    };

    return (
        <form onSubmit={handleSubmit}>
            <div>
                <label>Boy (cm):</label>
                <input type="number" name="boy" value={data.boy} onChange={handleChange} />
            </div>
            <div>
                <label>Kilo (kg):</label>
                <input type="number" name="kilo" value={data.kilo} onChange={handleChange} />
            </div>
            <div>
                <label>Yaş:</label>
                <input type="number" name="yas" value={data.yas} onChange={handleChange} />
            </div>
            <div>
                <label>Sigara Başlama Tarihi:</label>
                <input type="date" name="sigaraBaslama" value={data.sigaraBaslama} onChange={handleChange} />
            </div>
            <div>
                <label>Sigara Bırakma Tarihi:</label>
                <input type="date" name="sigaraBirakma" value={data.sigaraBirakma} onChange={handleChange} />
            </div>
            <div>
                <label>Günlük Ortalama Sigara Sayısı:</label>
                <input type="number" name="gunlukSigara" value={data.gunlukSigara} onChange={handleChange} />
            </div>
            <div>
                <label>Sigara Paket Fiyatı (TL):</label>
                <input type="number" name="paketFiyati" value={data.paketFiyati} onChange={handleChange} />
            </div>
            <div>
                <label>Sigarayı Bırakma Nedeni:</label>
                <input type="text" name="birakmaNedeni" value={data.birakmaNedeni} onChange={handleChange} />
            </div>
            <button type="submit">Verileri Göster</button>
        </form>
    );
}

export default Form;